<template>
  <div class="login-forget-pwd flex-align">

    <ul class="header flex-align">
      <li>
        <a href="/" rel="noopener noreferrer">
          <!-- <img src="@/assets/images/home/logo-three.png" alt /> -->
          <img src="@/assets/images/home/cun_new_logo_copy.png" alt />
        </a>
      </li>
      <!-- <li>
          <a href target="_blank" rel="noopener noreferrer">
            <img src="@/assets/images/home/unclogo.png" alt />
          </a>
        </li> -->
      <!-- <li>
          <a href target="_blank" rel="noopener noreferrer"
            >银川市双优云桥智慧研修学院</a
          >
        </li> -->
    </ul>
    <div class="board">
      <div class="container">
        <h2 class="back" @click="$router.push('/login')">
          <i class="el-icon-arrow-left"></i>返回登录页
        </h2>
        <div class="form-section">
          <!-- 步骤条 -->
          <div class="steps">
            <el-steps :active="active" finish-status="success">
              <el-step title="" icon="el-icon-check"></el-step>
              <el-step title="" icon="el-icon-check"></el-step>
              <el-step title="" icon="el-icon-check"></el-step>
            </el-steps>
          </div>
          <!--表单  -->
          <div class="form-phone" v-if="active === 0">
            <el-form key="phoneForm" :model="phoneForm" :rules="rule" status-icon ref="phoneForm" label-width="70px"
              class="demo-ruleForm" hide-required-asterisk>
              <el-form-item label="手机号：" prop="phone" key="phone">
                <el-input v-model.number="phoneForm.phone" placeholder="请输入手机号"></el-input>
              </el-form-item>
              <el-form-item label="验证码：" prop="verificode" key="verificode" class="vertificode">
                <el-input v-model.number="phoneForm.verificode" style="max-width:300px" placeholder="请输入验证码"></el-input>
                <el-button class="get-code" @click="getCode" v-if="showCode">获取验证码</el-button>
                <el-button class="get-code" v-else>{{`重新发送(${count}s)`}}</el-button>
              </el-form-item>
              <el-form-item label-width="0">
                <el-button type="primary" @click="submitForm('phoneForm')" class="next-btn">下一步</el-button>
              </el-form-item>
            </el-form>
          </div>


          <div class="form-modify-pwd" v-if="active === 1">
            <el-form :rules="passRule" key="passForm" :model="passForm" status-icon ref="passForm" label-width="50px"
              class="demo-ruleForm" hide-required-asterisk>
              <el-form-item prop="password" key="password">
                <el-input type="password" v-model="passForm.password" autocomplete="off" placeholder="请输入新密码">
                </el-input>
              </el-form-item>
              <el-form-item prop="checkPass" key="checkPass">
                <el-input type="password" v-model="passForm.checkPass" autocomplete="off" placeholder="请再次确认密码">
                </el-input>
              </el-form-item>

              <el-form-item label-width="0px">
                <el-button type="primary" @click="submitPassForm('passForm')" class="next-btn">下一步</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="modify-succeed" v-if="active >= 2">
            <img src="@/assets/images/login/succeed.png" alt="" />
            <!-- <p>修改成功</p> -->
            <el-button @click="next()" class="next-btn">去登陆</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      var checkPhone = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('手机号不能为空'));
        } else if (!/^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/.test(value)) {
          return callback(new Error('手机号格式错误'));
        } else {
          return callback();
        }
      };
      var checkCode = (rule, value, callback) => {
        // console.log(value)
        if (!value) {
          return callback(new Error('验证码不能为空'));
        } else {
          let params = {
            phone: this.phoneForm.phone,
            code: String(value)
          }
          this.$Api.Login.checkCode(params).then((resData) => {
            // console.log(resData)
            if (resData.data.result) {
              return callback();
            } else {
              this.$message({
                message: resData.data.msg,
                type: 'error'
              });
              return callback(new Error('验证码错误'));
            }
          })
        }
      };
      var checkPass = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('密码不能为空'));
        } else if (value.length < 6 || value.length > 20) {
          return callback(new Error('密码长度为6-20个字符'));
        } else {
          return callback();
        }
      };
      var checkPassAgain = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('请确认密码'));
        } else if (value !== this.passForm.password) {
          return callback(new Error('两次密码不一致'));
        } else {
          return callback();
        }
      };
      return {
        form: {
          phone: '',
          code: '',
          password: '',
          repeatPassword: '',
        },
        passForm: {
          password: "",
          checkPass: ""
        },
        phoneForm: {
          phone: "",
          verificode: ""
        },
        rule: {
          phone: [{
            required: true,
            trigger: "blur",
            validator: checkPhone
          }],
          verificode: [{
            required: true,
            message: "请输入验证码",
            trigger: "blur",
            validator: checkCode
          }],
        },
        passRule: {
          password: [{
            required: true,
            trigger: "blur",
            validator: checkPass
          }],
          checkPass: [{
            required: true,
            trigger: "blur",
            validator: checkPassAgain
          }],
        },
        active: 0,
        showCode: true,
        timer: "",
        count: 0

      };
    },
    methods: {
      // 获取验证码
      async getCode() {
        if (!/^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/.test(this.phoneForm
            .phone)) {
          this.$message({
            message: '手机号格式错误',
            type: 'error'
          });
          return
        }
        this.countDown();
        let params = {
          phone: this.phoneForm.phone
        }
        let resData = await this.$Api.Login.getVerifyCode(params)
        // console.log(resData);
        if (resData.code === 200 && resData.msg === "请求成功") {
          this.$message({
            message: '验证码发送成功',
            type: 'success'
          });
        } else {
          this.$message({
            message: resData.msg,
            type: 'error'
          });
        }
      },
      // 倒计时
      countDown() {
        const TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.showCode = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.showCode = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000)
        }
      },
      //提交phone-form
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.form.phone = String(this.phoneForm.phone)
            this.form.code = String(this.phoneForm.verificode)
            this.resetForm(formName);
            this.next();
          } else {
            return false;
          }
        });
      },
      //提交 pass-form
      async submitPassForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.form.password = this.passForm.password
            this.form.repeatPassword = this.passForm.checkPass
            this.commitForm();
          } else {
            return false;
          }
        });
      },
      async commitForm() {
        let resData = await this.$Api.Login.getFix_pass(this.form);
        console.log(resData);
        if (resData.code === 200 && resData.msg === "请求成功") {
          this.resetForm('passForm');
          this.next();
        } else {
          this.$notify({
            title: "错误",
            message: resData.msg
          });
          return false;
        }
      },
      //下一步
      next() {
        if (this.active++ >= 2) {
          this.active = 0;
          this.$router.push("/");
        }
      },
      //重置输入框内的数值,
      resetForm(formName) {
        this.$nextTick(() => {
          if (this.$refs[formName]) {
            this.$refs[formName].resetFields();
          }
        });
      }
    },
    mounted() {},
    activated() {},
    created() {},
    deactivated() {
      this.active = 0;
    }
  };
</script>

<style lang="less" scoped>
  .login-forget-pwd {
    position: relative;
    height: 100vh;
    max-height: 100vh;
    min-width: 1000px;
    background-color: #fff;
    background-image: url("../../assets/images/login/modify-bg.png");
    background-repeat: no-repeat;
    background-position: 50% 25%;
    background-size: cover;
    line-height: 1;

    .header {
      padding: 60px 0 0 165px;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9999;
    }

    .board {
      width: 841px;
      max-width: 1600px;
      height: 505px;
      // min-height: 600px;
      margin: 0 auto;
      // padding: 16px 0 0 25px;
      border-radius: 10px;
      // box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
      background-color: rgb(255, 255, 255);
    }

    .container {
      overflow: hidden;
    }

    // .header {
    //   li {
    //     margin-right: 16px;

    //     &:last-child a {
    //       // border-left: 2px solid #1e4c90;
    //       padding: 2px 16px;

    //       font-size: 16px;
    //       font-weight: bold;
    //       color: #1e4c90;
    //     }
    //   }
    // }

    .back {
      margin: 61px 0 59px 78px;
      font-size: 18px;
      color: #508EF9;
      cursor: pointer;

      i {
        font-weight: bold;
        margin-right: 10px
      }
    }

    .form-section {
      max-width: 375px;
      margin: 0 auto;
    }

    .steps {
      margin-bottom: 52px;

      /deep/ .el-step__head.is-process {
        .el-step__icon-inner {
          background-color: #508EF9;
        }
      }

      /deep/ .el-step__icon-inner {
        font-size: 22px;
        border-radius: 50%;
        padding: 6px;
        background-color: #e6e6e6;
        color: #fff;
        font-weight: bold;
      }

      /deep/ .el-step__head.is-success {
        border-color: #508EF9;

        .el-step__icon-inner,
        .el-step__line {
          background-color: #508EF9;
        }
      }
    }

    .form-phone,
    .form-modify-pwd,
    .modify-succeed {
      /deep/ .el-form-item {
        margin-bottom: 25px;
      }

      /deep/ .el-form-item__label {
        // line-height: 58px;
      }

      /deep/ .el-form-item__content {
        // line-height: 58px;

        .el-input {
          width: 266px;
        }

        .el-input__inner {
          // height: 58px;
          // line-height: 58px;
        }
      }

      .vertificode .el-input {
        width: 143px;
      }

      .next-btn {
        width: 317px;
        background-color: #508EF9;
        color: #fff;
        line-height: 43px;
        font-size: 20px;
        margin: 47px auto 0;
        display: block;
        border-radius: 25px;
        padding: 0;
      }
    }

    .form-phone {
      margin-left: -10px;

      .get-code {
        margin-left: 18px;
        border-radius: 5px;
        width: 105px;
        line-height: 40px;
        font-size: 13px;
        color: #fff;
        padding: 0;
        background-color: #508EF9;
        border: 0;
      }
    }

    .form-modify-pwd {
      // padding: 0 38px;
    }

    .modify-succeed {
      // padding: 0 38px;

      img {
        display: block;
        margin: 0 auto;
      }

      p {
        margin: 21px 0 0;
        font-size: 16px;
        color: #666;
        text-align: center;
      }
    }
  }
</style>